import { Box } from "@mui/system";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import { Typography } from "@mui/material";

export default function NoInternet() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "black",
      }}
    >
      <Box
        sx={{
          background: "#202741",
          height: "300px",
          width: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#FFFFFF",
          gap: "10px",
          borderRadius: "15px",
        }}
      >
        <CloudOutlinedIcon sx={{ fontSize: "8vw" }} />
        <Typography
          sx={{ fontSize: "2.5vw", fontWeight: "bold", textAlign: "center" }}
        >
          No internet connection
        </Typography>
        <Typography sx={{ fontSize: "1.5vw", textAlign: "center" }}>
          Make sure wifi or cellular connection is turned on <br /> and then try
          again{" "}
        </Typography>
      </Box>
    </Box>
  );
}
