import React, { useEffect, useMemo, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../assets/logo.png";
import bullionStats from "../assets/bullioStats.png";
import { deviceDetect } from "react-device-detect";
import {
  Box,
  Fade,
  LinearProgress,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Marquee from "react-fast-marquee";
import "../App.css";
import moment from "moment-timezone";
import io from "socket.io-client";
import Carousel from "react-material-ui-carousel";
import {
  allCommodities,
  allNewses,
  findBanners,
  findLoaction,
  findUserIp,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import { loginChecking, logoutTv, tvLoging } from "../sevice/login";
// import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LimitExceededModal from "./LimitExceededModal";
import SubscriptionExpiringSoon from "./SubscriptionExpiringSoon";
import SubscriptionExpired from "./SubscriptionExpired";
import Blocked from "./Blocked";
import CurrentLocation from "./CurrentLocation";
import { firebaseAuth } from "../sevice/config";
const { makeStyles } = require("@mui/styles");
let socket = localStorage.getItem("socketUrl")
  ? io(JSON.parse(localStorage.getItem("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;
const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100Vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "linear-gradient(180deg, #201C11 12.32%, #7D681B 138.11%)",
  },
  mainContainer: {
    width: "100%",
    height: "100vh",
    margin: "auto",
    padding: "2em",
    boxSizing: "border-box",
    columnGap: "2rem",
    display: "flex",
    flexDirection: "column",
    gap: 23,
    justifyContent: "space-between",
  },
  header: {
    width: "100%",
    height: "40%",
    display: "flex",
    justifyContent: "space-between",
  },
  spotRate: {
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    color: "#16341B",
  },
  spotRateHeader: {
    display: "flex",
    alignItems: "center",
    height: "22%",
    color: "#FFFFFF",
    borderTop: "1px solid #DDAF64",
    borderRight: "1px solid #DDAF64",
    borderLeft: "1px solid #DDAF64",
    // border: "1px solid #DDAF64",
    zIndex: 1000,
  },
  askOrBuyBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  dolorBox: {
    color: "#000000",
    background: "#FFFFFF",
    padding: "0px 9px 0px 9px",
    display: "inline-block",
    width: "fit-content",
    borderRadius: "4px",
  },
  spotRateBody: {
    height: "88%",
    display: "flex",
    padding: "1em",
    justifyContent: "space-evenly",
    flexDirection: "column",
    border: "1px solid #DDAF64",
  },
  spotRateRow: {
    height: "48%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
  },
  spotRateGoldBox: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateSilverBox: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateGoldValueBox: {
    border: "1px solid #fff",
    borderRadius: "5px",
    width: "8vw",
    height: "6vh",
    display: "flex",
    alignItems: "center",
    transition: "background-color 0.5s ease",
  },
  spotRateSilverValueBox: {
    border: "1px solid #fff",
    borderRadius: "5px",
    width: "8vw",
    height: "5.5vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color 0.5s ease",
  },
  highLowBox: {
    display: "flex",
    gap: "5px",
    flexDirection: "column",
    fontSize: ".9vw",
    // alignItems: "center",
  },
  high: {
    background: "#25B822",
    paddingRight: "9px",
    paddingLeft: "9px",
    display: "inline-block",
    width: "fit-content",
  },
  low: {
    background: "#E2261F",
    paddingRight: "9px",
    paddingLeft: "9px",
    display: "inline-block",
    width: "fit-content",
  },
  logo: {
    height: "100%",
    width: "40%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoImg: {
    height: "auto",
    width: "80%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  time: {
    width: "20%",
    display: "flex",
    color: "#fff",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  commodity: {
    height: "50%",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  table: {
    gridArea: "table",
    gridTemplateColumns: "1fr",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    boxSizing: "border-box",
    height: "100%",
    width: "60%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  tableHeader: {
    backgroundColor: "#DDAF64",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#060B12",
    height: "17%",
  },
  tableContent: {
    "&::-webkit-scrollbar": {
      display: "none", // Hide scrollbar
    },
    display: "flex",
    flexDirection: "column",
    gap: 8,
    overflowX: "scroll",
    height: "85%",
  },
  tableRow1: {
    minHeight: "18%",
    display: "flex",
    borderRadius: "10px",
    background: "#FFFAF1",
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#060B12",
    justifyContent: "flex-start",
    fontSize: "2vw",
    paddingLeft: "2.2rem",
  },
  carouselAndRatio: {
    height: "100%",
    width: "37%",
    display: "flex",
    flexDirection: "column",
    gap: 15,
    alignItems: "center",
  },
  carousel: {
    height: "60%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "35px",
  },
  bannerImg: {
    width: "100%",
    height: "27vh",
    objectFit: "cover",
    borderRadius: "35px",
  },
  stat: {
    height: "23%",
    width: "100%",
    gridArea: "stat",
    backgroundColor: "#281F11",
    color: "#69580E",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  bullionStatsImg: {
    width: "11vw",
    height: "auto",
  },
  ///////////////////////////
  updates: {
    color: "#000000",
    display: "flex",
    height: "8%",
    color: "#FFFFFF",
    alignItems: "end",
  },
  updatesHeader: {
    background: "#DDAF64",
    color: "#392F0E",
    minWidth: "200px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "7px 30px 0px 0px",
    boxShadow: "9px 0px 4px 0px #00000040",
    zIndex: 5,
  },
  updatesContent: {
    color: "#000000",
    display: "flex",
    height: "70%",
    background: "#DDAF64",
    width: "100%",
    marginLeft: -4,
  },

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  const dispatch = useDispatch();
  const { tv } = useSelector((state) => ({ ...state }));
  const classes = useStyles();

  const [banners, setBanners] = useState([]);
  const [news, setNews] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [openlimitExpierd, setOpenlimitExpierd] = useState(false);
  const [openBlocked, setOpenBlocked] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [userIp, setUserIp] = useState("Ip not found");
  const [location, setLocation] = useState("Unknown Address");
  const [openSubscription, setOpenSubscription] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [time, setTime] = useState(new Date());
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [render, setRender] = useState(true);
  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );

        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        if (elapsedTime <= 0) {
          clearInterval(timerId);
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  };

  useEffect(() => {
    handelFindBanners();
  }, []);

  useEffect(() => {
    handleFindRatio();
  }, []);

  useEffect(() => {
    hadlefetchNews();
  }, []);

  useEffect(() => {
    handleFindSpread();
  }, []);

  useEffect(() => {
    handleFinsCommodities();
  }, []);

  useEffect(() => {
    handleFindLiveValueTypeForDisplay();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      handelFindBanners();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleLogout();
    });
    socket2.on("socketRerender", (data) => {
      localStorage.setItem("socketUrl", null);
      window.location.reload();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
  }, []);

  useEffect(() => {
    handleFindSocketUrl();
  }, []);

  // useEffect(() => {
  //   if (latlng !== null) {
  //     handelFindLocation();
  //   }
  // }, [latlng]);

  useEffect(() => {
    sample();
    handleFindUserIp();
  }, []);

  useEffect(() => {
    if (deviceName && deviceId && userIp && !tv) {
      handleLogin();
    }
  }, [deviceName, deviceId, userIp]);

  function sample() {
    firebaseAuth
      .signInAnonymously()
      .then(async (user) => {
        setDeviceId(user?.user?.uid);
      })
      .catch(alert);
    const device = deviceDetect();
    if (device.isMobile) {
      setDeviceName(device.ua);
    } else {
      setDeviceName(device.userAgent);
    }
  }

  const handleFindUserIp = async () => {
    const res = await findUserIp();
    if (res?.ip) {
      setUserIp(res?.ip);
    }
  };

  //////////////////////login//////////////////////////////
  useEffect(() => {
    handleFindUserDatas();
  }, []);

  useEffect(() => {
    if (tv) {
      handleloginChecking();
    }
  }, []);

  useEffect(() => {
    if (tv) {
      const interval = setInterval(() => {
        triggerExpire();
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [tv]);

  useEffect(() => {
    if (tv) {
      handlecheckSubscriptionExpierd();
    }
  }, [tv]);

  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
    // setOpenBlocked(false);
  };

  async function handleFindUserDatas() {
    const deviceip = await findUserIp();
    setUserIp(deviceip.ip);
    firebaseAuth
      .signInAnonymously()
      .then(async (user) => {
        setDeviceId(user?.user?.uid);
      })
      .catch(alert);
    const device = deviceDetect();
    if (device.isMobile) {
      setDeviceName(device.ua);
    } else {
      setDeviceName(device.userAgent);
    }
  }

  useEffect(() => {
    if (deviceName && deviceId && userIp && !tv) {
      handleLogin();
    }
  }, [deviceName, deviceId, userIp]);

  const handleLogin = async () => {
    const res = await tvLoging({
      adminId: adminId,
      deviceName: deviceName,
      password: "123456",
      deviceId: deviceId,
      deviceIp: userIp,
      loginLocation: "Unknown Address",
    });
    if (res.status) {
      dispatch({ type: "LOGIN", payload: res });
      localStorage.setItem("tv", JSON.stringify(res));
      localStorage.setItem("deviceId", JSON.stringify(deviceId));
      handleloginChecking();
    } else {
      if (res == "You have blocked..!") {
        handleOpenBlocked();
        // toast.error(res);s
      } else {
        handleLogout();
      }
    }
  };

  const handleloginChecking = async () => {
    let deviceid = "";
    firebaseAuth
      .signInAnonymously()
      .then(async (user) => {
        deviceid = user?.user?.uid;
        const res = await loginChecking(deviceid);
        if (res.status === false && res?.totalLoginCount <= res?.liveCounts) {
          setOpenlimitExpierd(true);
        } else if (res.status === false) {
          localStorage.removeItem("tv");
          localStorage.removeItem("deviceId");
          localStorage.removeItem("socketUrl");
          // window.location.reload();
          dispatch({ type: "LOGOUT" });
          handleOpenBlocked();
        }
      })
      .catch(alert);
  };

  const handleLogout = async () => {
    const deviceId = localStorage.getItem("deviceId")
      ? JSON.parse(localStorage.getItem("deviceId"))
      : null;
    const res = await logoutTv({
      adminId: adminId,
      deviceId: deviceId,
    });
    if (res) {
      localStorage.removeItem("tv");
      localStorage.removeItem("deviceId");
      localStorage.removeItem("socketUrl");
      dispatch({ type: "LOGOUT" });
      window.location.reload();
    }
  };

  const triggerExpire = () => {
    const givenDate = new Date(tv?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();

    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleOpen();
      }
    }
  };

  const handlecheckSubscriptionExpierd = () => {
    const givenDate = new Date(tv?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  ////////////////////////////////////////////////////////

  // const handelFindLocation = async () => {
  //   const res = await findLoaction(latlng?.lat, latlng?.lng);
  //   console.log(res);
  //   if (res?.results[0]?.formatted_address) {
  //     setLocation(res?.results[0]?.formatted_address);
  //   }
  // };

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      localStorage.setItem("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);

  const buyersColor = "#25B822";
  const sellersColor = "#E2261F";

  const progressStyle = {
    backgroundColor: sellersColor,
  };

  const barStyle = {
    backgroundColor: buyersColor,
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };

  const formatday = () => {
    return moment(new Date()).format("dddd");
  };

  const handleOpen = () => setOpenSubscription(true);
  const handleClose = () => setOpenSubscription(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  return (
    <div
      style={{
        background: "#261A0B",
        height: "100vh",
      }}
      className={
        (openlimitExpierd || openSubscriptionExpierd || openBlocked) &&
        classes.blur
      }
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && (
            <Box
              sx={{
                position: "fixed",
                top: "0",
                left: "-125px",
                right: "0",
                bottom: "0",
                width: "895px",
                height: "531px",
                overflow: "hidden",
                zIndex: 1000000,
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "10%",
                  transform: "rotate(-35deg)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#DDAF64",
                  color: "#FFFFFF",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  padding: "20px",
                  lineHeight: 1.5,
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Marquee delay="2" gradient={false}>
                  {remainingTime && (
                    <p style={{ marginLeft: "90px" }}>
                      Market closed! Opens on{" "}
                      {formatRemainingTime(remainingTime)}
                    </p>
                  )}
                </Marquee>
              </div>
            </Box>
          )}
          <Box className={classes.header}>
            <Box className={classes.spotRate}>
              <Box className={classes.spotRateHeader}>
                <Typography
                  sx={{
                    fontSize: "1.4vw",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  SPOT RATE
                </Typography>
              </Box>
              <Box className={classes.spotRateBody}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    mx: 5,
                    color: "#FFFFFF",
                  }}
                >
                  <Box className={classes.askOrBuyBox}>
                    <Box className={classes.dolorBox}>
                      <Typography
                        style={{ fontSize: "1vw", fontWeight: "bold" }}
                      >
                        $
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "1vw", fontWeight: "bold" }}>
                      {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>oz</Typography>
                  </Box>
                  <Box className={classes.askOrBuyBox}>
                    <Box className={classes.dolorBox}>
                      <Typography
                        style={{ fontSize: "1vw", fontWeight: "bold" }}
                      >
                        $
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: "1vw", fontWeight: "bold" }}>
                      {displayBidOrBuy?.askOrSell?.toUpperCase()}
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>oz</Typography>
                  </Box>
                </Box>
                <Box
                  className={classes.spotRateRow}
                  sx={{ borderBottom: "1px solid #DDAF64" }}
                >
                  <Typography
                    sx={{
                      fontSize: "1.7vw",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    GOLD
                  </Typography>
                  <Box className={classes.spotRateGoldBox}>
                    <Box
                      sx={{
                        background:
                          Number(gold?.cur?.bid).toFixed(2) <
                          Number(gold?.pre?.bid).toFixed(2)
                            ? "#E2261F"
                            : Number(gold?.cur?.bid).toFixed(2) >
                              Number(gold?.pre?.bid).toFixed(2)
                            ? "#25B822"
                            : "#261A0B",
                        px: 1.5,
                      }}
                      className={classes.spotRateGoldValueBox}
                    >
                      <Typography
                        sx={{ fontSize: "2.2vw", fontWeight: "bold" }}
                      >
                        {(
                          Number(spread.goldBidSpread) + Number(gold?.cur?.bid)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.highLowBox}>
                    <Box className={classes.low}>
                      <Typography>Low</Typography>
                    </Box>
                    <Typography style={{ fontSize: "1.3vw" }}>
                      {(
                        Number(spread.goldLowSpread) +
                        Number(gold?.cur?.lowPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box className={classes.spotRateGoldBox}>
                    <Box
                      sx={{
                        background:
                          Number(gold?.cur?.ask).toFixed(2) <
                          Number(gold?.pre?.ask).toFixed(2)
                            ? "#E2261F"
                            : Number(gold?.cur?.ask).toFixed(2) >
                              Number(gold?.pre?.ask).toFixed(2)
                            ? "#25B822"
                            : "#261A0B",
                        px: 1.5,
                      }}
                      className={classes.spotRateGoldValueBox}
                    >
                      <Typography
                        sx={{ fontSize: "2.2vw", fontWeight: "bold" }}
                      >
                        {(
                          Number(spread.goldAskSpread) + Number(gold?.cur?.ask)
                        ).toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.highLowBox}>
                    <Box className={classes.high}>
                      <Typography>High</Typography>
                    </Box>
                    <Typography style={{ fontSize: "1.3vw" }}>
                      {(
                        Number(spread.goldHighSpread) +
                        Number(gold?.cur?.highPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.spotRateRow}>
                  <Typography
                    sx={{
                      fontSize: "1.5vw",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    SILVER
                  </Typography>

                  <Box className={classes.spotRateSilverBox}>
                    <Box
                      sx={{
                        background:
                          Number(silver?.cur?.bid).toFixed(2) <
                          Number(silver?.pre?.bid).toFixed(2)
                            ? "#E2261F"
                            : Number(silver?.cur?.bid).toFixed(2) >
                              Number(silver?.pre?.bid).toFixed(2)
                            ? "#25B822"
                            : "#261A0B",

                        px: 1.5,
                      }}
                      className={classes.spotRateSilverValueBox}
                    >
                      <Typography sx={{ fontSize: "2vw" }}>
                        &nbsp;&nbsp;
                        {(
                          Number(spread.silverBidSpread) +
                          Number(silver?.cur?.bid)
                        ).toFixed(2)}
                        &nbsp;&nbsp;
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.highLowBox}>
                    <Box className={classes.low}>
                      <Typography>Low</Typography>
                    </Box>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread.silverLowSpread) +
                        Number(silver?.cur?.lowPrice)
                      ).toFixed(2)}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </Box>
                  <Box className={classes.spotRateSilverBox}>
                    <Box
                      sx={{
                        background:
                          Number(silver?.cur?.ask).toFixed(2) <
                          Number(silver?.pre?.ask).toFixed(2)
                            ? "#E2261F"
                            : Number(silver?.cur?.ask).toFixed(2) >
                              Number(silver?.pre?.ask).toFixed(2)
                            ? "#25B822"
                            : "#261A0B",

                        px: 1.5,
                      }}
                      className={classes.spotRateSilverValueBox}
                    >
                      <Typography sx={{ fontSize: "2vw", ml: 1 }}>
                        &nbsp;&nbsp;
                        {(
                          Number(spread.silverAskSpread) +
                          Number(silver?.cur?.ask)
                        ).toFixed(2)}
                        &nbsp;&nbsp;
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.highLowBox}>
                    <Box className={classes.high}>
                      <Typography>High</Typography>
                    </Box>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread.silverHighSpread) +
                        Number(silver?.cur?.highPrice)
                      ).toFixed(2)}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.logo}>
              <img className={classes.logoImg} src={logo} alt="amiceLogo" />
            </Box>
            <Box className={classes.time}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2.9vw",
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    mb: -2,
                  }}
                >
                  {formatTime(time)
                    .split("")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "2vw",
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    mb: -1,
                  }}
                >
                  {formatday()
                    .split("")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>
                <Typography
                  sx={{
                    letterSpacing: "1px",
                    fontSize: "2vw",
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {formatDate()
                    .toUpperCase()
                    .split("")
                    .map((item, index) => (
                      <span key={index}>{item.toUpperCase()}</span>
                    ))}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.commodity}>
            <Box className={classes.carouselAndRatio}>
              <Box className={classes.stat}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 5,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1vw", color: "#25B822" }}>
                    BUYERS
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1vw",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#25B822"
                          : "#E2261F",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: "1vw", color: "#E2261F" }}>
                    SELLERS
                  </Typography>
                </Box>
                <Box sx={{ width: "90%", px: 4 }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1vw", color: "#25B822" }}>
                    {ratio?.Buyers}
                  </Typography>
                  <Typography sx={{ fontSize: "1vw", color: "#E2261F" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.carousel}>
                <Carousel
                  animation="fade" // Set the animation type to slide
                  navButtonsAlwaysVisible={false} // Show navigation buttons always
                  interval={10000}
                  indicatorContainerProps={{
                    style: {
                      display: "none",
                    },
                  }}
                  sx={{
                    height: "100%",
                    width: "100%",
                  }}
                  transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                  autoPlay
                  duration={2000}
                >
                  {banners?.map((banner, i) => (
                    <img
                      className={classes.bannerImg}
                      src={banner.imageUrl}
                      alt="banner"
                    />
                  ))}
                </Carousel>
              </Box>
              <Box sx={{ mt: 1, height: "17%" }}>
                <a href="https://www.bullionstats.com/">
                  <img
                    className={classes.bullionStatsImg}
                    src={bullionStats}
                    alt="bullionStats"
                  />
                </a>
              </Box>
            </Box>
            <Box className={classes.table}>
              <Box className={classes.tableHeader}>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: 4,
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  COMMODITY
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: 2,
                    // background: "white",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  WEIGHT
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: 4,
                    alignItems: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  BUY
                  <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
                <Typography
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "left",
                    paddingLeft: 4,
                    alignItems: "center",
                    fontSize: "2vw",
                    fontWeight: "bold",
                  }}
                >
                  SELL
                  <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                    &nbsp;AED
                  </Typography>
                </Typography>
              </Box>

              <Box className={classes.tableContent}>
                {commodities?.map((commodity, idx) => {
                  const words = commodity.commodity_title.split(" ");
                  return (
                    <Box key={idx} className={classes.tableRow1}>
                      <Typography
                        style={{ fontSize: "1.5vw", fontWeight: "bold" }}
                        className={classes.tableRowColumn}
                      >
                        {words.map((word, index) => {
                          if (index === 0) {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "2vw",
                                  fontWeight: "bold",
                                }}
                              >
                                {word}
                              </span>
                            );
                          } else {
                            return (
                              <span
                                key={index}
                                style={{
                                  fontSize: "1vw",
                                  marginTop: "0.2rem",
                                  fontWeight: "bold",
                                }}
                              >
                                &nbsp;{word}
                              </span>
                            );
                          }
                        })}
                        &nbsp;
                        {commodity.unitLabel === "TTB"
                          ? ""
                          : commodity.displayPurity}
                      </Typography>
                      <Typography
                        style={{
                          padding: 0,
                          fontSize: "2vw",
                          fontWeight: "bold",
                          justifyContent: "center",
                        }}
                        className={classes.tableRowColumn}
                      >
                        {commodity.unit}
                        {commodity.unitLabel}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "2vw",
                          fontWeight: "bold",
                        }}
                        className={classes.tableRowColumn}
                      >
                        {commodityCalculation(
                          commodity.commodity_title === "Silver"
                            ? silver?.cur?.bid
                            : gold?.cur?.bid,
                          commodity.commodity_title === "Silver"
                            ? spread.silverBidSpread
                            : spread.goldBidSpread,
                          commodity.buy_premium,
                          3.674,
                          commodity.purity,
                          commodity.unit,
                          commodity.unitLabel === "TTB"
                            ? 116.64
                            : commodity.unitLabel === "KG"
                            ? 1000
                            : commodity.unitLabel === "OZ"
                            ? 31.1
                            : commodity.unitLabel === "TOLA"
                            ? 11.7
                            : 1,
                          commodity.buy_charge
                        )}
                      </Typography>
                      <Typography
                        style={{ fontSize: "2vw", fontWeight: "bold" }}
                        className={classes.tableRowColumn}
                      >
                        {commodityCalculation(
                          commodity.commodity_title === "Silver"
                            ? silver?.cur?.ask
                            : gold?.cur.ask,
                          commodity.commodity_title === "Silver"
                            ? spread.silverAskSpread
                            : spread.goldAskSpread,
                          commodity.premium,
                          3.674,
                          commodity.purity,
                          commodity.unit,
                          commodity.unitLabel === "TTB"
                            ? 116.64
                            : commodity.unitLabel === "KG"
                            ? 1000
                            : commodity.unitLabel === "OZ"
                            ? 31.1
                            : commodity.unitLabel === "TOLA"
                            ? 11.7
                            : 1,
                          commodity.charges
                        )}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
          <Box className={classes.updates}>
            <Box className={classes.updatesHeader}>
              <Typography
                sx={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  textAlign: "center",
                  mt: "8px",
                  mb: "5px",
                  letterSpacing: "4px",
                }}
              >
                PYRITE
                <br />
                <Box
                  sx={{
                    fontSize: "1.3rem",
                    letterSpacing: "2px",
                    marginTop: -1,
                  }}
                >
                  UPDATES
                </Box>
              </Typography>
            </Box>
            <Box className={classes.updatesContent}>
              <marquee behavior="" direction="">
                <Box sx={{ display: "flex" }}>
                  {news?.map((item, index) => {
                    return (
                      <Box
                        key={item._id}
                        style={{
                          fontSize: "1.5vw",
                          fontFamily: "poppins",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {item.newsTitle}
                        </span>
                        &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                        <span
                          style={{ paddingRight: "50px", paddingLeft: "50px" }}
                        >
                          {index === news.length - 1 ? "" : "|"}
                        </span>
                      </Box>
                    );
                  })}
                </Box>
              </marquee>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openlimitExpierd}
        closeAfterTransition
      >
        <Fade in={openlimitExpierd}>
          <Box>
            <LimitExceededModal logout={handleLogout} />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscription}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openSubscription}>
          <Box>
            <SubscriptionExpiringSoon
              date={tv?.package.expire_date}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Fade in={openSubscriptionExpierd}>
          <Box>
            <SubscriptionExpired handleClose={handleLogout} />
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openBlocked}
        onClose={handleCloseBlocked}
        closeAfterTransition
      >
        <Fade in={openBlocked}>
          <Box>
            <Blocked handleClose={handleCloseBlocked} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Home;
